import React, { FC, RefObject } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { DirectionType } from '@helpers/enums.helper';
import { getAlternativeText } from '@helpers/altText.helper';
import { getLink } from '@helpers/linking.helper';

import { SlideData } from 'types';
import { PageType } from '@constants/pageType.constants';
import { Button } from '../../../button/Button';

import s from './SlidePhoto.module.scss';

interface ISlidePhoto {
  slide: SlideData;
  refSlide: RefObject<HTMLImageElement>;
  slideIndex: number;
}

const internalLinksUrlStartsWith = 'https://avionaut.com'

export const SlidePhoto: FC<ISlidePhoto> = ({ slide, refSlide, slideIndex }) => {
  const sendDataToGTM = useGTMDispatch();
  const intl = useIntl();

  return (
    <>
      <div className={s.slidePhoto__columnLeft}>
        <div className={s.slidePhoto__imageWrapper} ref={refSlide}>
          {
            slide.obrazek !== null &&
            <a
              href={slide.directiontype === DirectionType.address ? slide.directionaddress : `${getLink(intl.locale, PageType.CAR_SEATS)}/${slide.connectedproduct?.slug}`}
              target={slide.directiontype === DirectionType.address && slide.directionaddress.startsWith(internalLinksUrlStartsWith) ? '' : '_blank'}
              hrefLang={intl.locale} aria-label={'photo slider'} onClick={() => {
              sendDataToGTM({
                'event': 'hero-slider',
                'event_category': 'photo click',
                'event_label': `'${slide.formatabletext}'`,
                'event_value': slideIndex + 1,
                'event_language': intl.locale,
              });
            }
            }>
              <GatsbyImage
                loading={'eager'}
                image={getImage(slide.obrazek.localFile)!}
                className={s.slidePhoto__image}
                alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__1' }), slide.obrazek.altText)}
              />
            </a>
          }
        </div>
      </div>
      <div className={s.slidePhoto__columnRight}>
        {
          slide.formatabletext !== null &&
          <div className={s.slidePhoto__text} dangerouslySetInnerHTML={{ __html: slide.formatabletext }}/>
        }
        <div className={s.slidePhoto__button} onClick={() => {
          sendDataToGTM({
            'event': 'hero-slider',
            'event_category': 'button click',
            'event_label': `'${slide.directionaddress}'`,
            'event_value': slideIndex + 1,
            'event_language': intl.locale,
          });
        }}>
          {
            slide.directiontype === DirectionType.address ?
              slide.directionaddress.startsWith(internalLinksUrlStartsWith) ?
                <Button yellow href={slide.directionaddress} isSubPage>
                  {slide.buttontext}
                </Button> :
                <Button yellow href={slide.directionaddress}>
                  {slide.buttontext}
                </Button> :
              <Button yellow pageType={PageType.CAR_SEAT} slug={slide.connectedproduct?.slug}>
                {slide.buttontext}
              </Button>
          }
        </div>
      </div>
    </>
  );
};
