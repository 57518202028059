import React, { FC, RefObject } from 'react';

import { SlidePhoto } from './slide-photo/SlidePhoto.component';
import { SlideVideo } from './slide-video/SlideVideo.component';

import { SlideData } from 'types';
import { SlideType } from '@helpers/enums.helper';

import s from './Slide.module.scss';

interface ISlide {
  slide: SlideData;
  setVideoCodeAndPlayVideo: (code: string) => void;
  refSlide: RefObject<HTMLImageElement>;
  slideIndex: number;
}

export const Slide: FC<ISlide> = ({slide, refSlide, setVideoCodeAndPlayVideo, slideIndex }) => {
  
  return (
    <div className={s.slide}>
      <div className={s.slide__wrapper}>
        {slide.slidetype === SlideType.photo ?
          <SlidePhoto
            refSlide={refSlide}
            slide={slide}
            slideIndex={slideIndex}
          />
          : <SlideVideo
            slide={slide}
            refSlide={refSlide}
            setVideoCodeAndPlayVideo={setVideoCodeAndPlayVideo}
            slideIndex={slideIndex}
          />
        }
      </div>
    </div>
  );
};

