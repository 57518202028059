import React, { FC, useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useWindowSize } from '../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';

import { Container } from '../container/Container.component';

import { Atests } from './atests/Atests.component';
import { Testimonial } from './testimonial/Testimonial.component';

import s from './Proofs.module.scss';


export const Proofs: FC = () => {
  const refAtestsWrapper = useRef<HTMLDivElement>(null);
  const refTestimonial = useRef<HTMLDivElement>(null);
  const refProofs = useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
    }
  }, []);

  useEffect(() => {
    if (refTestimonial.current !== null && refAtestsWrapper.current !== null && refProofs.current !== null) {
      const atests = refAtestsWrapper.current.children;
      let targets;

      if (isMobile) {
        targets = [...atests, refTestimonial.current];
      } else {
        targets = [
          atests[0],
          refTestimonial.current,
          atests[1],
          atests[2],
          atests[3],
          atests[4],
          atests[5],
          atests[6],
          atests[7],
          atests[8],
          atests[9],
          atests[10],
        ];
      }

      gsap.set(targets, {
        autoAlpha: 0,
        y: 20,
      });

      gsap.to(targets, {
        autoAlpha: 1,
        y: 0,
        stagger: .8,
        ease: 'expo.out',
        duration: 4,
        scrollTrigger: {
          once: true,
          trigger: refProofs.current,
          scrub: .5,
          start: () => isMobile ? '10% 80%' : '15% 80%',
          end: () => isMobile ? '90% 0' : '50% 0',
        },
      });

      return () => {
        gsap.killTweensOf(targets);
      };
    }
  }, [refTestimonial.current, refAtestsWrapper.current, refProofs.current, isMobile]);

  return (
    <section className={s.proofs} ref={refProofs}>
      <Container>
        <div className={s.proofs__content}>
          <div className={s.proofs__atest}>
            <Atests refWrapper={refAtestsWrapper} />
          </div>
          <div className={s.proofs__testimonial}>
            <Testimonial refTestimonial={refTestimonial} />
          </div>
        </div>
      </Container>
    </section>
  );
};
