import React, { Dispatch, FC, RefObject, SetStateAction } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { ExplainerVideo, PhotoFileFluid } from 'types';

import Arrow from '@assets/svg/arrow.svg';

import s from './ExplainerMobile.module.scss';
import { Play } from '../../play/Play.component';

interface IExplainerMobile {
  videos: ExplainerVideo[];
  refVideosWrapper: RefObject<HTMLDivElement>;
  areVideosPlaying: boolean;
  videoPoster: PhotoFileFluid;

  setAreVideosPlaying: Dispatch<SetStateAction<boolean>>;
  setCurrentActiveIndexAsPrevious: () => void;
  setActiveVideoIndex: Dispatch<SetStateAction<number>>;
  setNextVideo: () => void;
  isVideoActive: (index: number) => boolean;
}

export const ExplainerMobile: FC<IExplainerMobile> = (props) => {
    const {
      videos,
      refVideosWrapper,
      areVideosPlaying,
      videoPoster,
      setNextVideo,
      setActiveVideoIndex,
      setCurrentActiveIndexAsPrevious,
      setAreVideosPlaying,
      isVideoActive,
    } = props;

    const intl = useIntl();
    const sendDataToGTM = useGTMDispatch()

  return (
      <div className={`${s.explainerMobile} ${!areVideosPlaying && s.noVideo}`}>
        {
          !areVideosPlaying &&
          <div className={s.explainerMobile__overlay}>
            <GatsbyImage image={getImage(videoPoster)!} className={s.explainerMobile__poster}
                         alt={intl.formatMessage({ id: 'alt__videoPoster' })} />
            <div className={s.explainerMobile__play}>
              <Play
                watchVideoText={intl.formatMessage({ id: 'home__playVideo' })}
                onClick={() => {
                  setAreVideosPlaying(true);
                  sendDataToGTM({
                    'event': 'explainer-video',
                    'event_category': 'play click',
                    'event_language': intl.locale,
                  })
                }}
                isBlack
              />
            </div>
          </div>
        }
        <div className={`${s.explainerMobile__videoWrapper} ${!areVideosPlaying && s.noVideo}`} ref={refVideosWrapper}>
          {
            areVideosPlaying &&
            videos.map((video: ExplainerVideo) => (
              <video
                className={s.explainerMobile__video}
                key={video.name}
                preload={'none'}
                autoPlay
                playsInline
                loop
                muted
              >
                <source src={video.src} type={'video/mp4'} />
              </video>),
            )
          }
        </div>
        <div className={s.explainerMobile__controls}>
          <ul className={s.explainerMobile__steps}>
            {
              videos.map((video: ExplainerVideo, index: number) => (
                <li
                  className={`${s.explainerMobile__step} ${isVideoActive(index) ? s.active : ''}`}
                  key={video.name}
                  onClick={() => {
                    setCurrentActiveIndexAsPrevious();
                    setActiveVideoIndex(index);
                    sendDataToGTM({
                      'event': 'explainer-video',
                      'event_category': 'dot click',
                      'event_value': index+1,
                      'event_language': intl.locale,
                    })
                  }}
                >
                  {index + 1}
                </li>),
              )
            }
          </ul>
          <div className={s.explainerMobile__next} onClick={ () => {
            setNextVideo();
            sendDataToGTM({
              'event': 'explainer-video',
              'event_category': 'arrow click',
              'event_language': intl.locale,
            })
          }}>
            <Arrow className={s.explainerMobile__nextSvg} />
          </div>
        </div>
      </div>
    );
  }
;
