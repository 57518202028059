import React, { FC, RefObject } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { SlideData } from 'types';

import { Play } from '../../../play/Play.component';

import s from './SlideVideo.module.scss';

interface ISlideVideo {
  slide: SlideData
  refSlide: RefObject<HTMLImageElement>;
  setVideoCodeAndPlayVideo: (code: string) => void;
  slideIndex: number;
}

export const SlideVideo: FC<ISlideVideo> = ({ slide, refSlide, setVideoCodeAndPlayVideo, slideIndex }) => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch()

  return (
    <div className={s.slideVideo}>
      <div className={s.slideVideo__coverWrapper}>
        {
          slide.slidevideocover !== null &&
          <div ref={refSlide}>
            <GatsbyImage
              image={getImage(slide.slidevideocover.localFile)!}
              loading={'eager'}
              className={s.slideVideo__cover}
              alt={'slide'}
            />
          </div>
        }
        <div className={s.slideVideo__play}>
          <Play
            onClick={() => {
              setVideoCodeAndPlayVideo(slide.slidevideocode);
              sendDataToGTM({
                'event': 'hero-slider',
                'event_category': 'play click',
                'event_label': `'${slide.slidevideocode}'`,
                'event_value': slideIndex+1,
                'event_language': intl.locale,
              })
            }}
            watchVideoText={intl.formatMessage({ id: 'home__playVideo' })}
          />
        </div>
      </div>
    </div>
  );
};
