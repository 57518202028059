import { PageType } from '@constants/pageType.constants';
import React from 'react';
import Product from '@components/product/Product.component';

import s from '@components/featured-categories/components/FeaturedCategoriesDesktop.module.scss';
import Tag from '@components/product/Tag.component';
import { useIntl } from 'gatsby-plugin-intl';

interface IDesktopPresentationComponent {
  popularProducts: any;
}

const DesktopPresentationComponent = ({ popularProducts }: IDesktopPresentationComponent) => {
  const intl = useIntl();

 
  const tagTranslation: string[] = [
    intl.formatMessage({ id: 'tag__0' }),
    intl.formatMessage({ id: 'tag__1' }),
    intl.formatMessage({ id: 'tag__2' }),
  ];
  
  return (
    <section className={s.featuredCategoriesDesktop} id={'featured-categories-section'}>
      {popularProducts.map(product => {
        
        const isCompatibleWithIQBase = product.productMetaData.iscompatiblewithiqbase;
        const isCompatibleWithIQBase20C = product.productMetaData.iscompatiblewithiqbase20c;
        const isCloudCare = product.productMetaData.iscloudcare;
        const isProductIncludedInNewStyleCampaign = product.productMetaData.newcolorversionavailable;
        
        return (
          <Product
            name={product.productMetaData.productname}
            colors={product.productData.productavailablecolors}
            pageType={PageType.CAR_SEAT}
            colorVisible
            isCompatibleWithIQBase={isCompatibleWithIQBase}
            isCompatibleWithIQBase20C={isCompatibleWithIQBase20C}
            isCloudCare={isCloudCare}
            isProductIncludedInNewStyleCampaign={isProductIncludedInNewStyleCampaign}
            slug={product.slug}
            thumbnail={product.productMetaData.thumbnail}
            key={product.slug}
            thumbnailPhotos={product.productData.thumbnailphotos}
            avionautShopUrl={product.productData.avionautShopUrl}
          >
            {product.productData.tags && product.productData.tags.map((tag) => {
              return (
                <Tag key={tag} value={tagTranslation[parseInt(tag,10)]} />
              );
            })}
            {product.productData.producttags && product.productData.producttags.map((tag) => {
              return (
                <Tag key={tag.tagname} value={tag.tagname} />
              );
            })}
          </Product>
        );
      })}
    </section>
  );
};

export default DesktopPresentationComponent;
