import React, { FC } from 'react';

import s from './Tag.module.scss';

interface ITag {
  value: string
}

const Tag: FC<ITag> = ({ value }) => {

  return <div className={s.tag}>{value}</div>;

};
export default Tag;
