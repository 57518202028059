import React from 'react';

import Star from '@assets/svg/star.svg';

import s from './StarRating.module.scss';

interface IStarRaiting {
  rating: number;
}

const StarRaiting = ({rating}:IStarRaiting) => {
  return (
    <div className={s.starRating}>
      <Star className={`${s.starRating__star} ${rating >= 1 ? s.starRating__checked : '' }`}/>
      <Star className={`${s.starRating__star} ${rating >= 2 ? s.starRating__checked : '' }`}/>
      <Star className={`${s.starRating__star} ${rating >= 3 ? s.starRating__checked : '' }`}/>
      <Star className={`${s.starRating__star} ${rating >= 4 ? s.starRating__checked : '' }`}/>
      <Star className={`${s.starRating__star} ${rating >= 5 ? s.starRating__checked : '' }`}/>
    </div>
  )
}

export default StarRaiting;
