import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';
import {getImage} from 'gatsby-plugin-image';

import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';

import {Container} from '@components/container/Container.component';
import {LineHeader} from '@components/dont-miss-articles/components/LineHeader.component';
import ArticleCard from '@components/article-card/ArticleCard.component';
import {PageType} from '@constants/pageType.constants';
import {Button} from '@components/button/Button';

import s from './DontMissArticles.module.scss';
import {Article} from '../../types/product.types';
import {useLocalizedDynamicRoute} from '../../hooks/use-localized-dynamic-route';


interface IDontMissArticles {
  posts: Article[];
}

const DontMissArticles = ({ posts }: IDontMissArticles) => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();
  const link = useLocalizedDynamicRoute();
  
  return (
    <section>
      <Container>
        <LineHeader text={intl.formatMessage({ id: 'dontMiss' })}/>
        <div className={s.dontMiss__articles}>
          {
            posts.map((post) => {
              
              return (
                <ArticleCard
                  image={getImage(post.node.featuredImage.node.localFile)!}
                  altText={post.node.title}
                  header={post.node.title}
                  articleLink={post.node.slug}
                  cta={intl.formatMessage({ id: 'warrantyBannerArticle_cta' })}
                  currentLocale={intl.locale}
                  eventLabel={'dontMissArticles'}
                />
              );
            })
          }
        </div>
        <div className={s.dontMiss__button}>
          <Button pageType={PageType.KNOWLEDGE_BASE} onClick={() => {
              sendDataToGTM({
                'event': 'more-articles',
                'event_language': intl.locale,
              });
            }}>
            {intl.formatMessage({id: 'moreArticles'})}
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default DontMissArticles;
