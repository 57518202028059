import React from 'react';
import { Opinion } from '../../../types/homepage.types';

import OpinionWidget from '../../customers-opinion-widget/components/OpinionWidget.component';

import s from './MedicalOpinionsDesktop.module.scss';


interface IMedicalOpinionsDesktop {
  opinions: Opinion[];
}

const MedicalOpinionsDesktop = ({ opinions }: IMedicalOpinionsDesktop) => {
  return (
    <div className={s.medicalOpinionsDesktop}>
        <div className={s.medicalOpinionsDesktop__grid}>
          <OpinionWidget customerOpinion={opinions[0]}/>
          <OpinionWidget customerOpinion={opinions[1]}/>
          <OpinionWidget customerOpinion={opinions[2]}/>
          <OpinionWidget customerOpinion={opinions[3]}/>
        </div>
    </div>
  );
};

export default MedicalOpinionsDesktop;
