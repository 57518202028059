import React, { createRef, Dispatch, FC, RefObject, SetStateAction, useEffect, useRef } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useIntl } from 'gatsby-plugin-intl';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { ExplainerVideo } from 'types/homepage.types';

import Arrow from '@assets/svg/arrow.svg';

import s from './ExplainerDesktop.module.scss';

interface IExplainerDesktop {
  videos: ExplainerVideo[];
  refVideosWrapper: RefObject<HTMLDivElement>;
  areVideosPlaying: boolean;

  setAreVideosPlaying: Dispatch<SetStateAction<boolean>>;
  setCurrentActiveIndexAsPrevious: () => void;
  setActiveVideoIndex: Dispatch<SetStateAction<number>>;
  setNextVideo: () => void;
  isVideoActive: (index: number) => boolean;
}

export const ExplainerDesktop: FC<IExplainerDesktop> = (props) => {
  const {
    videos,
    refVideosWrapper,
    areVideosPlaying,
    setNextVideo,
    setActiveVideoIndex,
    setCurrentActiveIndexAsPrevious,
    setAreVideosPlaying,
    isVideoActive,
  } = props;

  const refVideos = useRef<RefObject<HTMLVideoElement>[]>(videos.map(() => createRef()));
  const sendDataToGTM = useGTMDispatch()
  const intl = useIntl();

  const loadAndPlayVideos = () => {
    refVideos.current.forEach((refVideo: RefObject<HTMLVideoElement>) => {
      if (refVideo.current !== null) {
        refVideo.current.muted = true;
        refVideo.current.play();
      }
    });
  };

  useEffect(() => {
    if (areVideosPlaying) {
      loadAndPlayVideos();
    }
  }, [areVideosPlaying]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (refVideosWrapper.current) {

      const scrollTrigger = ScrollTrigger.create({
        trigger: refVideosWrapper.current,
        start: '-50% bottom',
        onEnter: () => {
          setAreVideosPlaying(true);
        },
        onEnterBack: () => {
          setAreVideosPlaying(true);
        },
      });

      return () => {
        scrollTrigger.kill();
      };
    }
  }, [refVideosWrapper.current]);

  return (
    <div className={s.explainerDesktop}>
      <div className={s.explainerDesktop__videoWrapper} ref={refVideosWrapper}>
        {
          videos.map((video: ExplainerVideo, index) => (
            <video
              className={s.explainerDesktop__video}
              key={video.name}
              ref={refVideos.current && refVideos.current[index]}
              preload={'none'}
              playsInline
              loop
              muted
            >
              <source src={video.src} type={'video/mp4'} />
            </video>),
          )
        }
      </div>
      <div className={s.explainerDesktop__controls}>
        <ul className={s.explainerDesktop__steps}>
          {
            videos.map((video: ExplainerVideo, index: number) => (
              <li
                className={`${s.explainerDesktop__step} ${isVideoActive(index) ? s.active : ''}`}
                key={video.name}
                onClick={() => {
                  setCurrentActiveIndexAsPrevious();
                  setActiveVideoIndex(index);
                  sendDataToGTM({
                    'event': 'explainer-video',
                    'event_category': 'dot click',
                    'event_value': index+1,
                    'event_language': intl.locale,
                  })
                }}
              >
                {index + 1}
              </li>),
            )
          }
        </ul>
        <div className={s.explainerDesktop__next} onClick={ () => {
          setNextVideo();
          sendDataToGTM({
            'event': 'explainer-video',
            'event_category': 'arrow click',
            'event_language': intl.locale,
          })        
        }}>
          <Arrow className={s.explainerDesktop__nextSvg} />
        </div>
      </div>
    </div>
  );
};
