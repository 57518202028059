import {GuaranteeData} from '../types/guarantee.types';

export const getArticleImage = (banner: GuaranteeData) => {
  if (banner.warrantyArticle) {
    return {
      image: banner.warrantyArticle[0].featuredImage.node.localFile.childImageSharp.gatsbyImageData,
      altText: banner.warrantyArticle[0].featuredImage.node.altText,
      header: banner.warrantyArticle[0].title,
      link: banner.warrantyArticle[0].slug,
    };
  } else {
    return {
      image: banner.warrantyArticleImage!.localFile.childImageSharp.gatsbyImageData,
      altText: banner.warrantyArticleImage!.altText,
      header: banner.warrantyArticleHeader!,
      link: banner.warrantyArticleLink!,
    };
  }
};

export const medicinesCategoryAllSupportedLangs = ['medicine', 'medecine', 'medizin', 'medycyna'];
