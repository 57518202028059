import React from 'react';

import { CustomersOpinion } from '../../../types/product.types';

import StarRaiting from '../../star-rating/StarRating.component';

import s from './OpinionWidget.module.scss';


interface IOpinionWidget {
  customerOpinion: CustomersOpinion
}

const OpinionWidget = ({customerOpinion}: IOpinionWidget) => {
  return (
    <div className={s.opinionWidget__content}>
      <div className={s.opinionWidget__firstRow}>
        <p className={s.opinionWidget__quote}>
          “
        </p>
        <p className={s.opinionWidget__opinion}>{customerOpinion.opinion}</p>
      </div>
      <StarRaiting rating={customerOpinion.rating}/>
      <p className={s.opinionWidget__client}>{customerOpinion.client}</p>
    </div>
  )
}

export default OpinionWidget;
