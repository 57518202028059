import React, { FC, useEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';

import { ExplainerVideo, PhotoFileFluid } from 'types';
import { useWindowSize } from '../../hooks/use-window-size';
import { useMobile } from '../../hooks/use-mobile';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';

import { Header } from '../header/Header.component';
import { ExplainerDesktop } from './explainer-desktop/ExplainerDesktop.component';
import { ExplainerMobile } from './explainer-mobile/ExplainerMobile.component';

import s from './Explainer.module.scss';

interface IExplainer {
  videos: ExplainerVideo[];
  videoPoster: PhotoFileFluid;
  header: string;
}

export const Explainer: FC<IExplainer> = ({ videos, header, videoPoster }) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState<number>(0);
  const [areVideosPlaying, setAreVideosPlaying] = useState<boolean>(false);

  const isMobileUserAgent = useMobile();
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.TABLET);

  const refVideosWrapper = useRef<HTMLDivElement>(null);
  const refDescription = useRef<HTMLDivElement>(null);
  const refPreviousIndex = useRef<number>(0);

  const timeline = gsap.timeline();

  const isVideoActive = (index: number): boolean => (index === activeVideoIndex);

  const setNextVideo = (): void => {
    refPreviousIndex.current = activeVideoIndex;
    if (activeVideoIndex === videos.length - 1) {
      setActiveVideoIndex(0);
    } else {
      setActiveVideoIndex(value => value + 1);
    }
  };

  const setCurrentActiveIndexAsPrevious = () => {
    refPreviousIndex.current = activeVideoIndex;
  };

  const animateToCurrentVideo = () => {
    const VIDEO_WIDTH_MOBILE = 160;
    const VIDEO_WIDTH_DESKTOP = 100;

    if (refVideosWrapper.current !== null) {
      const width = isMobile ? VIDEO_WIDTH_MOBILE : VIDEO_WIDTH_DESKTOP;
      let xValue;
      if (isMobile) {
        xValue = activeVideoIndex === 0 ? `-30vw` : `-${activeVideoIndex * width + 30}vw`;
      } else {
        xValue = activeVideoIndex === 0 ? 0 : `-${activeVideoIndex * width}vw`;
      }

      gsap.to(refVideosWrapper.current, {
          x: xValue,
        },
      );
    }
  };

  const animateDescription = () => {
    if (refDescription.current !== null) {
      const offsetOut = activeVideoIndex > refPreviousIndex.current ? -100 : 100;
      const offsetIn = activeVideoIndex > refPreviousIndex.current ? 100 : -100;

      timeline
        .fromTo(refDescription.current, {
          x: 0,
          autoAlpha: 1,
        }, {
          x: offsetOut,
          autoAlpha: 0,
          duration: .1,
        })
        .fromTo(refDescription.current, {
          x: offsetIn,
          autoAlpha: 0,
        }, {
          x: 0,
          autoAlpha: 1,
          duration: .2,
        });
    }
  };

  useEffect(() => {
    animateDescription();

    return () => {
      timeline.kill();
    };
  }, [activeVideoIndex]);

  useEffect(() => {
    animateToCurrentVideo();

    if (areVideosPlaying) {
      const interval = setInterval(() => {
        setNextVideo();
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [activeVideoIndex, windowSize, areVideosPlaying]);

  return (
    <section className={s.explainer} id={'explainer'}>
      <div className={s.explainer__header}>
        <Header>
          {header}
        </Header>
      </div>
      <div className={s.explainer__slider}>
        {
          isMobileUserAgent
            ? <ExplainerMobile
              videos={videos}
              refVideosWrapper={refVideosWrapper}
              areVideosPlaying={areVideosPlaying}
              videoPoster={videoPoster}

              setAreVideosPlaying={setAreVideosPlaying}
              isVideoActive={isVideoActive}
              setActiveVideoIndex={setActiveVideoIndex}
              setNextVideo={setNextVideo}
              setCurrentActiveIndexAsPrevious={setCurrentActiveIndexAsPrevious}
            />

            : <ExplainerDesktop
              videos={videos}
              refVideosWrapper={refVideosWrapper}
              areVideosPlaying={areVideosPlaying}

              setAreVideosPlaying={setAreVideosPlaying}
              isVideoActive={isVideoActive}
              setActiveVideoIndex={setActiveVideoIndex}
              setNextVideo={setNextVideo}
              setCurrentActiveIndexAsPrevious={setCurrentActiveIndexAsPrevious}
            />
        }

        <div className={s.explainer__description} ref={refDescription}>
          <h3 className={s.explainer__title}>{videos[activeVideoIndex].title}</h3>
          <p className={s.explainer__paragraph}>{videos[activeVideoIndex].description}</p>
        </div>
      </div>
    </section>
  );
};
