import React from 'react';
import { Header } from '@components/header/Header.component';
import { useIntl } from 'gatsby-plugin-intl';

import { Container } from '@components/container/Container.component';

import s from './ProducerAvionaut.module.scss';
import sanitizeHtml from 'sanitize-html';
import { replaceNbsp } from '@helpers/translation.helper';


const ProducerAvionaut = () => {
  const intl = useIntl();

  return (
    <section>
      <Container>
        { ( intl.locale === 'pl' || intl.locale === 'de' ) &&
          <div className={s.producerAvionaut}>
            <div className={s.producerAvionaut__header}>
              <Header isMainHeader>{intl.formatMessage({ id: 'HomePageHeaderH1' })}</Header>
            </div>
            <div className={s.producerAvionaut__description}>
              <p className={s.producerAvionaut__text} dangerouslySetInnerHTML={{
                __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'HomePageDescriptionH1' })), {
                  allowedTags: ['strong', 'a', 'br'],
                  allowedAttributes: {
                    'a': ['href'],
                  },
                }),
              }}/>
            </div>
          </div>
        }
      </Container>
    </section>
  );
};

export default ProducerAvionaut;
