export const mapRange = (
  x: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
) => (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;


export const mapAnimationRange = (draggableX: number, index: number) => {
  const range = 200;
  const value = ((draggableX + (index * window.innerWidth)) / window.innerWidth) * 100;
  return ((value + 100) / range) * 100;
}; 
