import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { useWindowSize } from '../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import { getAlternativeText } from '@helpers/altText.helper';

import MedicalOpinionsMobile from './components/MedicalOpinionsMobile.component';
import MedicalOpinionsDesktop from './components/MedicalOpinionsDesktop.component';

import s from './MedicalApprovement.module.scss';


export const MedicalApprovement = () => {
  
  const intl = useIntl();
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);

  const articleImageAltText = getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__8' }), intl.formatMessage({ id: 'meetVisionAvionaut__picAlt' }))

  const opinions = [
    {
      opinion: intl.formatMessage({ id: 'MedicalOpinion__firstOpinion' }),
      rating: 5,
      client: intl.formatMessage({ id: 'MedicalOpinion__firstClient' })
    },
    {
      opinion: intl.formatMessage({ id: 'MedicalOpinion__secondOpinion' }),
      rating: 5,
      client: intl.formatMessage({ id: 'MedicalOpinion__secondClient' })
    },
    {
      opinion: intl.formatMessage({ id: 'MedicalOpinion__thirdOpinion' }),
      rating: 5,
      client: intl.formatMessage({ id: 'MedicalOpinion__thirdClient' })
    },
    {
      opinion: intl.formatMessage({ id: 'MedicalOpinion__fourthOpinion' }),
      rating: 5,
      client: intl.formatMessage({ id: 'MedicalOpinion__fourthClient' })
    }
  ]
  
  return (
    <section className={s.medicalApprovement}>
      <div className={s.medicalApprovement__content}>
        <div className={s.medicalApprovement__information}>
          <h2 className={s.medicalApprovement__header}>{intl.formatMessage({ id: 'medicalApprovement__header' })}</h2>
          <StaticImage
            src={'../../assets/images/medical-logo.png'}
            alt={articleImageAltText}
            objectFit={'cover'}
            className={s.medicalApprovement__logo}
          />
        </div>
        <div className={s.medicalApprovement__person}>
          <StaticImage
            src={'../../assets/images/jagoda-approve.jpg'}
            alt={articleImageAltText}
            objectFit={'cover'}
          />
        </div>
        <div className={s.medicalApprovement__shadow} />
      </div>
      { isMobile ? <MedicalOpinionsMobile opinions={opinions} /> : <MedicalOpinionsDesktop opinions={opinions} /> }
    </section>
  )
}
