import React from 'react'
import s from './LineHeader.module.scss';

interface ILineHeader {
 text: string;
}

export const LineHeader = ({text}: ILineHeader) => {

  return(
    <div className={s.lineHeader}>
      <h2 className={s.lineHeader__header}>{text}</h2>
      <div className={s.lineHeader__line} />
    </div>
  )
}
