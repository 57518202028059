import React from 'react';
import { Opinion } from '../../../types/homepage.types';

import CustomersOpinionWidget from '../../customers-opinion-widget/CustomersOpinionWidget.component';

import s from './MedicalOpinionsMobile.module.scss';

interface IMedicalOpinionsMobile {
  opinions: Opinion[]
}

const MedicalOpinionsMobile = ({ opinions }:IMedicalOpinionsMobile) => {
  return (
    <div className={s.medicalOpinionsMobile}>
      <CustomersOpinionWidget customersOpinion={opinions} product={'medical-opinion-hp'}/>
    </div>
  )
}

export default MedicalOpinionsMobile;
