import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';

import { useIntl } from 'gatsby-plugin-intl';

import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';
import { UspHeader } from '../usp-header/Usp-header.component';
import { Signet } from './singnet/Signet.component';

import { useMobile } from '../../hooks/use-mobile';

import s from './Usp.module.scss';

export const Usp: FC = () => {
  const intl = useIntl();

  const signet = useRef(null);
  const pathRef = React.createRef();
  const isMobile = useMobile();

  const animateSygnet = (degrees) => {
    if (signet && signet.current && !isMobile) {
      gsap.to(signet.current, {
        rotation: degrees,
        ease: 'ease',
      });
    }
  };

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin);
  }, []);

  useEffect(() => {
    if (pathRef.current) {
      gsap.set(pathRef.current, {
        transformOrigin: 'center center',
        cursor: 'grab',
      });

      Draggable.create(pathRef.current, {
        type: 'rotation',
        inertia: true,
        transformOrigin: 'bottom',
        bounds: { minRotation: -90, maxRotation: 90 },
        snap: [0],
        onPress: () => {
          gsap.set(pathRef.current, {
            cursor: 'grabbing',
          });
        },
        onClick: () => {
          gsap.set(pathRef.current, {
            cursor: 'grab',
          });
        },
        onDragEnd: () => {
          gsap.set(pathRef.current, {
            cursor: 'grab',
          });
        },
      });
    }
  }, [signet.current, pathRef.current]);

  return (
    <section className={s.usp} id={'usp'}>
      <Container>
        <Header children={intl.formatMessage({ id: 'home__uspHeader' })} />
        <div className={s.usp__content}>
          <div className={s.usp__life}
               onMouseOver={() => animateSygnet(-30)}
               onMouseLeave={() => animateSygnet(0)}
          >
            <UspHeader
              title={intl.formatMessage({ id: 'home__uspSectionTopLeftHeader' })}
              description={intl.formatMessage({ id: 'home__uspSectionTopLeftContent' })}
            />
          </div>
          <div className={s.usp__logo} ref={signet}>
            <Signet forwardedPath={pathRef} />
          </div>
          <div className={s.usp__safety}
               onMouseOver={() => animateSygnet(30)}
               onMouseLeave={() => animateSygnet(0)}
          >
            <UspHeader
              title={intl.formatMessage({ id: 'home__uspSectionTopRightHeader' })}
              description={intl.formatMessage({ id: 'home__uspSectionTopRightContent' })}
            />
          </div>
          <div className={s.usp__innovation}
               onMouseOver={() => animateSygnet(-60)}
               onMouseLeave={() => animateSygnet(0)}
          >
            <UspHeader
              title={intl.formatMessage({ id: 'home__uspSectionBottomLeftHeader' })}
              description={intl.formatMessage({ id: 'home__uspSectionBottomLeftContent' })}
            />
          </div>
          <div className={s.usp__comfort}
               onMouseOver={() => animateSygnet(60)}
               onMouseLeave={() => animateSygnet(0)}
          >
            <UspHeader
              title={intl.formatMessage({ id: 'home__uspSectionBottomRightHeader' })}
              description={intl.formatMessage({ id: 'home__uspSectionBottomRightContent' })}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
