/* webpackPreload: true */
import React, { createRef, FC, RefObject, useEffect, useRef, useState } from 'react';
import { gsap, Power2 } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Loadable from '@loadable/component';
// @ts-ignore
const ModalVideo = Loadable(() => import('react-modal-video'));

import { SliderEdge } from 'types';
import { mapAnimationRange } from '@helpers/math.helper';
import { useWindowSize } from '../../hooks/use-window-size';
import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { Slide } from './slide/Slide.component';
import { ClientOnly } from '../../app/shared/components/client-only/ClientOnly.component';

import Signet from '@assets/svg/signet-thin.svg';
import Arrow from '@assets/svg/arrow.svg';

import s from './HeroSlider.module.scss';

interface IHeroSlider {
  edges: SliderEdge[];
}

export const HeroSlider: FC<IHeroSlider> = ({ edges }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [isOnFirstSlide, setIsOnFirstSlide] = useState<boolean>(true);
  const [isOnLastSlide, setIsOnLastSlide] = useState<boolean>(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [videoCode, setVideoCode] = useState<string>('');

  const refAllSlides = useRef<RefObject<HTMLImageElement>[]>(edges.map(() => createRef<HTMLImageElement>()));
  const refSlider = useRef<HTMLDivElement>(null);
  const refSignet = useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();
  const sendDataToGTM = useGTMDispatch()
  const intl = useIntl();

  const SIGNET_PARALLAX_FACTOR = 30;
  let draggable: Draggable | null = null;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(Draggable, InertiaPlugin, ScrollToPlugin);
    }
  }, []);

  const goToSlide = (index: number) => {
    if (draggable !== null) {

      gsap.to(draggable.target, {
        x: () => -window.innerWidth * index,
        onUpdate: () => {
          if (draggable) {
            draggable.update();
          }
          animateSignetAndImagesOnSlidesProgress();
        },
        onComplete: () => {
          setActiveSlideIndex(index);
        },
      });
    }
  };

  const animateSignetAndImagesOnSlidesProgress = () => {
    if (refSignet.current !== null && draggable) {
      gsap.set(refSignet.current, {
        x: () => {
          if (draggable) {
            return -draggable.x / SIGNET_PARALLAX_FACTOR;
          }

          return 0;
        } 
      });

      edges.map((_, index) => {
        if (draggable) {
          const value = mapAnimationRange(draggable.x, index);
          const target = refAllSlides.current[index].current?.children[0].children[0].children[2]?.children[1];

          if (target) {
            gsap.to(target!, {
              objectPosition: () => `${value}% 0%`,
            });
          }
        }

      });
    }
  };

  const setVideoCodeAndPlayVideo = (code: string) => {
    setVideoCode(code);
    setIsVideoPlaying(true);
  };

  const resetVideoCodeAndPlayVideo = () => {
    setVideoCode('');
    setIsVideoPlaying(false);
  };

  const gotoAnchor = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const anchor = document.getElementById('featured-products');
      const posTop = anchor && anchor.offsetTop || 0;

      gsap.to(window, 1, { scrollTo: { y: posTop }, ease: Power2.easeOut });
    }
  };

  useEffect(() => {
    if (refSlider.current !== null && typeof window !== `undefined`) {

      gsap.set(refSlider.current, {
        width: () => `${edges.length * 100}vw`,
      });

      const MIN_X = -window.innerWidth * (edges.length - 1);
      const MAX_X = 0;

      draggable = new Draggable(refSlider.current, {
        type: 'x',
        inertia: true,
        throwResistance: 50,
        dragResistance: 0,
        bounds: {
          minX: MIN_X,
          maxX: MAX_X,
          minY: 0,
          maxY: 0,
        },
        snap: {
          x: value => Math.round(value / window.innerWidth) * window.innerWidth,
        },
        onDragStart: () => {
          sendDataToGTM({
            'event': 'hero-slider',
            'event_category': 'draggable',
            'event_label': 'on-drag-start',
            'event_value': activeSlideIndex+1,
            'event_language': intl.locale,
          })
        },
        onDrag: animateSignetAndImagesOnSlidesProgress,
        onThrowUpdate: animateSignetAndImagesOnSlidesProgress,
        onThrowComplete: () => {
          if (draggable) {
            const index = Math.round(draggable.x / window.innerWidth);
            index === 0 ? setActiveSlideIndex(index) : setActiveSlideIndex(-index);
          }
        },
        zIndexBoost: false,
      });
    }
  }, [edges, refSlider.current, activeSlideIndex, isOnFirstSlide, isOnLastSlide, windowSize]);

  useEffect(() => {
    if (activeSlideIndex === 0) {
      setIsOnFirstSlide(true);
    } else {
      setIsOnFirstSlide(false);
    }

    if (activeSlideIndex === edges.length - 1) {
      setIsOnLastSlide(true);
    } else {
      setIsOnLastSlide(false);
    }
  }, [activeSlideIndex, edges]);

  useEffect(() => {
    goToSlide(activeSlideIndex);
  }, [windowSize]);

  return (
    <div className={s.heroSlider}>
      <div className={s.heroSlider__signet} ref={refSignet}>
        <Signet className={s.heroSlider__signetSvg} />
      </div>
      <div className={s.heroSlider__pagination}>
        {
          edges.map((_, index) => {
            return (
              <div className={s.heroSlider__bulletWrapper} key={index} onClick={() => {
                goToSlide(index);
                sendDataToGTM({
                  'event': 'hero-slider',
                  'event_category': 'pagination click',
                  'event_value': index+1,
                  'event_language': intl.locale,
                })
              }}>
                <div className={`${s.heroSlider__bullet} ${index === activeSlideIndex ? s.active : ''}`} />
              </div>
            );
          })
        }
      </div>
      {
        !isOnFirstSlide &&
        <div
          className={`${s.heroSlider__arrow} ${s.heroSlider__arrowLeft}`}
          onClick={() => {
            goToSlide(activeSlideIndex - 1);
            sendDataToGTM({
              'event': 'hero-slider',
              'event_category': 'arrow click',
              'event_label': 'back',
              'event_language': intl.locale,
            })
          }}
        >
          <Arrow className={s.heroSlider__arrowSvg} />
        </div>
      }
      {
        !isOnLastSlide &&
        <div
          className={`${s.heroSlider__arrow} ${s.heroSlider__arrowRight}`}
          onClick={() => {
            goToSlide(activeSlideIndex + 1);
            sendDataToGTM({
              'event': 'hero-slider',
              'event_category': 'arrow click',
              'event_label': 'next',
              'event_language': intl.locale,
            })
          }}
        >
          <Arrow className={s.heroSlider__arrowSvg} />
        </div>
      }
      <div className={s.heroSlider__slider} ref={refSlider}>
        {
          edges.map((edge, index) => {
            return <Slide
              key={index}
              slide={edge.node.slideData}
              refSlide={refAllSlides.current[index]}
              setVideoCodeAndPlayVideo={setVideoCodeAndPlayVideo}
              slideIndex={index}
            />;
          })
        }
      </div>
      <div className={s.heroSlider__scrollArrow} onClick={() => {
        gotoAnchor();
        sendDataToGTM({
          'event': 'hero-slider',
          'event_category': 'scroll-arrow click',
          'event_language': intl.locale,
        })
      }}>
        <Arrow className={s.heroSlider__scrollArrowSvg} />
      </div>
      <ClientOnly>
        <ModalVideo channel="youtube"
                    youtube={{ autoplay: 1 }}
                    isOpen={isVideoPlaying}
                    videoId={videoCode}
                    onClose={resetVideoCodeAndPlayVideo}
        />
      </ClientOnly>
    </div>
  );
};
