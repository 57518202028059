import React, { FC, ReactNode, RefObject, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { scaleDown, scaleUp } from '@helpers/background.animations';
import { FacebookURL, InstagramURL, LinkedInURL } from '@helpers/socials.helper';
import { getAlternativeText } from '@helpers/altText.helper';

import { Button } from '../button/Button';

import Facebook from '@assets/svg/facebook.svg';
import Instagram from '@assets/svg/instagram.svg';
import LinkedIn from '@assets/svg/linkedin.svg';

import s from './SocialTiles.module.scss';


interface ISocialTiles {
  buttonText: string;
  darkVariant?: boolean;
}

export const SocialTiles: FC<ISocialTiles> = ({ buttonText, darkVariant }) => {

  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch()

  const socialFb = useRef<HTMLDivElement>(null);
  const socialIg = useRef<HTMLDivElement>(null);
  const socialLi = useRef<HTMLDivElement>(null);
  const socialFbBg = useRef<HTMLDivElement>(null);
  const socialIgBg = useRef<HTMLDivElement>(null);
  const socialLiBg = useRef<HTMLDivElement>(null);


  const renderSocials = (icon: ReactNode, link: string, socialIcon: RefObject<HTMLDivElement>) => {

    return (
        <a href={link} hrefLang={intl.locale} target="_blank" rel="noopener noreferrer" aria-label={'social media icon'}>
          <div className={s.socialTiles__icon} ref={socialIcon}>
            {icon}
          </div>
        </a>
    );
  };

  return (
    <div className={`${s.socialTiles__middle} ${ darkVariant? s.dark : '' }`}>
      <div className={s.socialTiles__social}
           onMouseOver={() => scaleUp(socialIgBg.current?.children[0].children[2].children[1])}
           onMouseLeave={() => scaleDown(socialIgBg.current?.children[0].children[2].children[1])}
      >
        <div ref={socialIgBg}>
          <StaticImage
            src={'../../assets/images/image-middle.jpg'}
            className={s.socialTiles__middleImg}
            alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__1' }), intl.formatMessage({ id: 'alt__instagramBackground' }))}
          />
        </div>
        {renderSocials(
          <Instagram className={s.socialTiles__instagramSvg} onClick={() => {
            sendDataToGTM({
              'event': 'social-box',
              'event_category': 'icon click',
              'event_label': 'instagram',
              'event_language': intl.locale,
            })
          }}/>,
          InstagramURL[intl.locale],
          socialIg,
        )}
        <div className={s.socialTiles__button} onClick={() => {
          sendDataToGTM({
            'event': 'social-box',
            'event_category': 'button click',
            'event_label': 'instagram',
            'event_language': intl.locale,
          })
        }}>
          <Button white href={InstagramURL[intl.locale]}>
            <p className={s.socialTiles__buttonText}>{buttonText}</p>
          </Button>
        </div>
      </div>
      <div className={s.socialTiles__left}>
        <div className={s.socialTiles__social}
             onMouseOver={() => scaleUp(socialFbBg.current?.children[0].children[2].children[1])}
             onMouseLeave={() => scaleDown(socialFbBg.current?.children[0].children[2].children[1])}
        >
          <div ref={socialFbBg}>
            <StaticImage
              src={'../../assets/images/image-left.jpg'}
              className={s.socialTiles__leftImg}
              alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__2' }), intl.formatMessage({ id: 'alt__facebookBackground' }))}
            />
          </div>
          {renderSocials(
            <Facebook className={s.socialTiles__facebookSvg} onClick={() => {
              sendDataToGTM({
                'event': 'social-box',
                'event_category': 'icon click',
                'event_label': 'facebook',
                'event_language': intl.locale,
              })
            }}/>,
            FacebookURL[intl.locale],
            socialFb,
          )}
          <div className={s.socialTiles__button} onClick={() => {
            sendDataToGTM({
              'event': 'social-box',
              'event_category': 'button click',
              'event_label': 'facebook',
              'event_language': intl.locale,
            })
          }}>
            <Button white href={FacebookURL[intl.locale]}>
              <p className={s.socialTiles__buttonText}>{buttonText}</p>
            </Button>
          </div>
        </div>
      </div>
      <div className={s.socialTiles__right}>
        <div className={s.socialTiles__social}
             onMouseOver={() => scaleUp(socialLiBg.current?.children[0].children[2].children[1])}
             onMouseLeave={() => scaleDown(socialLiBg.current?.children[0].children[2].children[1])}
        >
          <div ref={socialLiBg}>
            <StaticImage
              src={'../../assets/images/image-right.jpg'}
              className={s.socialTiles__rightImg}
              alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__3' }), intl.formatMessage({ id: 'alt__linkedinBackground' }))}
            />
          </div>
          {renderSocials(
            <LinkedIn className={s.socialTiles__linkedinSvg} onClick={() => {
              sendDataToGTM({
                'event': 'social-box',
                'event_category': 'icon click',
                'event_label': 'linkedIn',
                'event_language': intl.locale,
              })
            }}/>,
            LinkedInURL[intl.locale],
            socialLi,
          )}
          <div className={s.socialTiles__button} onClick={() => {
            sendDataToGTM({
              'event': 'social-box',
              'event_category': 'button click',
              'event_label': 'linkedIn',
              'event_language': intl.locale,
            })
          }}>
            <Button white href={LinkedInURL[intl.locale]}>
              <p className={s.socialTiles__buttonText}>{buttonText}</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
