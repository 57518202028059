import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Swiper, SwiperSlide } from 'swiper/react';

import Product from '@components/product/Product.component';
import { PageType } from '@constants/pageType.constants';
import Tag from '@components/product/Tag.component';

import s from '@components/featured-categories/components/FeaturedCategoriesMobile.module.scss';

interface IMobilePresentationComponent {
  popularProducts: any;
}

const MobilePresentationComponent = ({ popularProducts }: IMobilePresentationComponent) => {
  const intl = useIntl();

  const tagTranslation: string[] = [
    intl.formatMessage({ id: 'tag__0' }),
    intl.formatMessage({ id: 'tag__1' }),
    intl.formatMessage({ id: 'tag__2' }),
  ];

  return (
    <div className={s.featuredCategoriesMobile}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        speed={800}
        loop={true}
        lazy={true}
        preloadImages={true}
        updateOnImagesReady={true}
        pagination={{
          clickable: true,
          renderBullet: (_index: number, className) => {
            return '<span class="' + className + '">' + ' ' + '</span>';
          },
        }}
      >
        {
          popularProducts.map(product => {
            
            const isCompatibleWithIQBase = product.productMetaData.iscompatiblewithiqbase;
            const isCompatibleWithIQBase20C = product.productMetaData.iscompatiblewithiqbase20c;
            const isCloudCare = product.productMetaData.iscloudcare;
            const isProductIncludedInNewStyleCampaign = product.productMetaData.newcolorversionavailable;

            return (
              <SwiperSlide>
                <div className={s.featuredCategoriesMobile__slider}>
                  <Product
                    name={product.productMetaData.productname}
                    colors={product.productData.productavailablecolors}
                    colorVisible
                    pageType={PageType.CAR_SEAT}
                    isCompatibleWithIQBase={isCompatibleWithIQBase}
                    isCompatibleWithIQBase20C={isCompatibleWithIQBase20C}
                    isCloudCare={isCloudCare}
                    isProductIncludedInNewStyleCampaign={isProductIncludedInNewStyleCampaign}
                    slug={product.slug}                    thumbnail={product.productMetaData.thumbnail}
                    key={product.id}
                    thumbnailPhotos={product.productData.thumbnailphotos}
                    avionautShopUrl={product.productData.avionautShopUrl}
                  >
                    {product.productData.tags && product.productData.tags.map((tag) => {
                      return (
                        <Tag key={tag} value={tagTranslation[parseInt(tag, 10)]}/>
                      );
                    })}
                    {product.productData.producttags && product.productData.producttags.map((tag) => {
                      return (
                        <Tag key={tag.tagname} value={tag.tagname}/>
                      );
                    })}
                  </Product>
                </div>
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </div>
  );
};

export default MobilePresentationComponent;
