import React, { FC } from 'react';

import { useIntl } from 'gatsby-plugin-intl';

import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';
import { SocialTiles } from '../social-tiles/SocialTiles.component';

import s from './Follow.module.scss';

interface IFollow {
  buttonText: string
}

export const Follow: FC<IFollow> = ({ buttonText }) => {
  const intl = useIntl();

  return (
    <section className={s.follow}>
      <Container>
        <div className={s.follow__header}>
          <Header children={intl.formatMessage({ id: 'home__followUsHeader' })} />
        </div>
        <SocialTiles buttonText={buttonText}/>
      </Container>
    </section>
  );
};
