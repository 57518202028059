import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { useWindowSize } from '../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import { getAlternativeText } from '@helpers/altText.helper';

import AvionautVisionLogo from '@assets/svg/avionaut-vision.svg';
import AvionautVisionSignet from '@assets/svg/vision-signet.svg';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { Button } from '../button/Button';

import s from './MeetVisionAvionaut.module.scss';

export const MeetVisionAvionaut = () => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);
  
  return (
    <section className={s.meetVisionAvionaut}>
      <div className={s.meetVisionAvionaut__content}>
        <div className={s.meetVisionAvionaut__information}>
          <AvionautVisionSignet className={s.meetVisionAvionaut__signet} />
          <AvionautVisionLogo className={s.meetVisionAvionaut__logo} />
          <p className={s.meetVisionAvionaut__subtitle}>{intl.formatMessage({ id: 'meetVisionAvionaut__subtitle' })}</p>
          <p className={s.meetVisionAvionaut__description}>{intl.formatMessage({ id: 'meetVisionAvionaut__description' })}</p>
          <div className={s.meetVisionAvionaut__button} onClick={() => {
            sendDataToGTM({
              'event': 'vision-avionaut-hp',
              'event_category': 'button click',
              'event_language': intl.locale,
            });
          }}>
            <Button responsive isSubPage href={intl.formatMessage({ id: 'meetVisionAvionaut__cta' })} sand >{intl.formatMessage({ id: 'meetVisionAvionaut__button' })}</Button>
          </div>
        </div>
        <div className={s.meetVisionAvionaut__mother}>
          <StaticImage
            src={'../../assets/images/mammy.jpg'}
            alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__9' }), intl.formatMessage({ id: 'meetVisionAvionaut__picAlt' }))}
            className={s.meetVisionAvionaut__img}
            objectFit={'cover'}
            objectPosition={ isMobile ? '20% 0' : '50% 0%'}
          />
        </div>
      </div>
    </section>
  )
}
