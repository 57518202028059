import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CustomersOpinion } from '../../types/product.types';

import OpinionWidget from './components/OpinionWidget.component';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useIntl } from 'gatsby-plugin-intl';

import SwiperClass from 'swiper/types/swiper-class';

import s from './CustomersOpinionWidget.module.scss';


interface ICustomersOpinion {
  customersOpinion: CustomersOpinion[];
  product: string;
}

SwiperCore.use([Pagination]);

const CustomersOpinionWidget = ({customersOpinion, product}:ICustomersOpinion) => {
  const sendDataToGTM = useGTMDispatch();
  const intl = useIntl();

  return (
    <div className={s.customersOpinion}>
      <Swiper
        spaceBetween={70}
        slidesPerView={1}
        speed={800}
        lazy={true}
        onSlideChange={(swiper: SwiperClass)=>{
          sendDataToGTM({
            'event': 'customers-opinion-widget',
            'event_category': 'on slide change',
            'event_label': `${product}`,
            'event_value':`${swiper.activeIndex+1}/${swiper.slides.length}`,
            'event_language': intl.locale,
          })
        }}
        pagination={{
          clickable: true,
          renderBullet: (_index: number, className) => {
            return '<span class="' + className + '">' + ' ' + '</span>';
          },
        }}
      >
      {
        customersOpinion.map((customerOpinion,index) => {
          return (
            <SwiperSlide key={index}>
              <OpinionWidget customerOpinion={customerOpinion}/>
            </SwiperSlide>
          )
        })
      }
      </Swiper>
    </div>
  )
}
export default CustomersOpinionWidget;
