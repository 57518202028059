import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {useIntl} from 'gatsby-plugin-intl';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';

import Background from '@assets/svg/post-accidnet-replacemnet-round.svg';

import {articleDictionaryHelper} from '@components/post-accident-replacement-home-page/PostAccidentReplacement.helper';

import s from './PostAccidentReplacementHomePage.module.scss';
import sb from '../button/Button.module.scss';
import {Link} from 'gatsby';
import {useLocalizedDynamicRoute} from '../../hooks/use-localized-dynamic-route';
import {PageType} from '@constants/pageType.constants';


export const PostAccidentReplacementHomePage = () => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();
  const link = useLocalizedDynamicRoute();

  return (
    <section className={s.postAccidentReplacementHomePage}>
      <div className={s.postAccidentReplacementHomePage__content}>
        <div className={s.postAccidentReplacementHomePage__article}>
          <StaticImage
            src={'../../assets/images/post-accident-replacement-bg.jpg'}
            alt={''}
            className={s.postAccidentReplacementHomePage__background}
            objectFit={'cover'}
          />
        </div>
        <Background className={s.postAccidentReplacementHomePage__logo}/>
        <div className={s.postAccidentReplacementHomePage__information}>
          <h2 className={s.postAccidentReplacementHomePage__header} onClick={() =>
            sendDataToGTM({
              'event': 'post-accident-replacement-hp',
              'event_category': 'header click',
              'event_language': intl.locale,
            })
          }>{intl.formatMessage({id: 'postAccidentReplacementHomePage__header'})}</h2>
          <p
            className={`${s.postAccidentReplacementHomePage__divider} ${s.postAccidentReplacementHomePage__description}`}
            onClick={() =>
              sendDataToGTM({
                'event': 'post-accident-replacement-hp',
                'event_category': 'text click',
              'event_language': intl.locale,
              })
            }>{intl.formatMessage({id: 'postAccidentReplacementHomePage__textFirstPart'})}</p>
          <p className={s.postAccidentReplacementHomePage__description} onClick={() =>
            sendDataToGTM({
              'event': 'post-accident-replacement-hp',
              'event_category': 'text click',
              'event_language': intl.locale,
            })
          }>{intl.formatMessage({id: 'postAccidentReplacementHomePage__textSecondPart'})}</p>
          <div className={s.postAccidentReplacementHomePage__button} onClick={() =>
            sendDataToGTM({
              'event': 'post-accident-replacement-hp',
              'event_category': 'button click',
              'event_language': intl.locale,
            })
          }>
            <Link to={link(PageType.KNOWLEDGE_BASE_ARTICLES, articleDictionaryHelper[intl.locale])}
                  className={`${sb.button} ${sb.red} ${sb.responsive}`}>
              {intl.formatMessage({id: 'meetVisionAvionaut__button'})}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PostAccidentReplacementHomePage
