import React from 'react';
import { ReactNode } from 'react';

import s from './Box.module.scss';

interface IBox {
  header: string;
  information: ReactNode;
}

const Box = ({ header, information }:IBox) => {
  return (
    <div className={s.box}>
      <h2 className={s.box__header}>{header}</h2>
      <div>{information}</div>
    </div>
  )
}

export default Box;
