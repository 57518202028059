import React, { Ref, RefObject } from 'react';
import s from './Signet.module.scss';

interface ISignet {
  forwardedPath: Ref<SVGPathElement>;
}

export const Signet = React.forwardRef<RefObject<SVGPathElement>, ISignet>(({ forwardedPath }) => {
  return (
    <svg
      className={s.signet}
      xmlns="http://www.w3.org/2000/svg"
      width="367"
      height="367"
      fill="none"
      viewBox="0 0 367 367"
    >
      <path
        fill="#323232"
        fillRule="evenodd"
        d="M183.639 0C82.206 0 0 81.927 0 183.361 0 284.794 81.927 367 183.361 367 284.794 367 367 285.073 367 183.639 367 82.484 284.794.279 183.639 0zM310.71 256.092c-3.901-.557-6.409-2.508-8.639-6.967l-71.337-142.397c-6.967-14.769-11.147-20.342-38.735-19.228-34.833 1.115-39.849 5.852-30.095 25.08l81.091 156.609c8.638 15.048 15.048 17.556 33.997 16.999h28.423c-29.259 34.833-73.288 56.847-122.054 56.847-88.058 0-159.396-71.617-159.117-159.674 0-23.408 5.294-45.98 14.49-66.044h20.9c3.901.558 6.41 2.508 8.639 6.967l71.059 142.397c6.966 14.769 11.146 20.343 38.734 19.228 34.833-1.115 39.849-5.852 30.096-25.08l-81.37-156.609c-8.639-15.048-15.048-17.556-33.997-16.998H56.29C85.55 49.045 131.53 24.244 183.64 24.244c88.058 0 159.396 71.616 159.117 159.674 0 25.916-6.409 50.717-17.277 72.174H310.71z"
        clipRule="evenodd"
        ref={forwardedPath}
      />
    </svg>
  );
});
