import { gsap } from 'gsap';

export const scaleUp = (background: Element | undefined) => {
  if (background) {
    gsap.to(background, {
      scale: 1.1,
      duration: 1,
      transformOrigin: '50% 50%',
      ease: 'power2.out',
    });
  }
};

export const scaleDown = (background: Element | undefined) => {
  if (background) {
    gsap.to(background, {
      scale: 1,
      transformOrigin: '50% 50%',
      duration: 1,
      ease: 'power2.out',
    });
  }
};
