import React, { createRef, FC, RefObject, useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { gsap } from 'gsap';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { Draggable } from 'gsap/Draggable';

import { useWindowSize } from '../../../hooks/use-window-size';
import { mapAnimationRange } from '@helpers/math.helper';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import { getAlternativeText } from '@helpers/altText.helper';

import s from './Testimonial.module.scss';

interface ITestimonial {
  refTestimonial: RefObject<HTMLDivElement>;
}

export const Testimonial: FC<ITestimonial> = ({ refTestimonial }) => {
  const MAX_TILE_WIDTH = 570;
  
  const intl = useIntl();
  
  const {
    doctor,
    jagoda,
  } = useStaticQuery(graphql`
    query GetDoctor {
      doctor: file(name: {eq: "avatar"}) {
        name
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED,
            quality: 100
            placeholder: BLURRED
            formats: [AUTO,WEBP]
          )
        }
      },
      jagoda: file(name: {eq: "jagoda-testimonial"}) {
        name
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED,
            quality: 100
            placeholder: BLURRED
            formats: [AUTO,WEBP]
          )
        }
      },
    }
  `);
  
  const Testimonials = [
    {
      image: getImage(doctor),
      alt: getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__10' }), intl.formatMessage({ id: 'alt__physiotherapist' })),
      subtitle: intl.formatMessage({ id: 'home__physiotherapist' }),
      name: <>Michał<br/>Budnicki</>,
      comment: intl.formatMessage({ id: 'home__physiotherapistComment' }),
    },
    {
      image: getImage(jagoda),
      alt: getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__8' }), intl.formatMessage({ id: 'alt__midwife' })),
      subtitle: intl.formatMessage({ id: 'home__midwife' }),
      name: <>Jagoda<br/>Jurczyńska</>,
      comment: intl.formatMessage({ id: 'home__midwifeComment' }),
    },
  ];
  
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  
  const refAllSlides = useRef<RefObject<HTMLImageElement>[]>(Testimonials.map(() => createRef<HTMLImageElement>()));
  const refSlider = useRef<HTMLDivElement>(null);
  const refSignet = useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();
  const isMinTablet = isMobileResolution(windowSize, BREAKPOINT.MIN_TABLET);
  let draggable: Draggable | null = null;

  const getCard = (person, personReference, personKey) => {
    return (
      <div className={s.testimonial__person} ref={personReference} key={personKey}>
        <div className={s.testimonial__doctor}>
          <GatsbyImage
            image={person.image}
            className={s.testimonial__doctorImg} 
            alt={person.alt}/>
        </div>
        <p className={s.testimonial__subtitle}>
          {person.subtitle}
        </p>
        <h3 className={s.testimonial__title}>
          {person.name}
        </h3>
        <div className={s.testimonial__text}>
          <p className={s.testimonial__quote}>
            “
          </p>
          <p className={s.testimonial__description}>
            {person.comment}
          </p>
        </div>
      </div>
    );
  };

  const goToSlide = (index: number) => {
    if (draggable !== null) {
      
      gsap.to(draggable.target, {
        // x: () => -MAX_TILE_WIDTH * index,
        x: () => isMinTablet ? -(window.innerWidth) * index : -MAX_TILE_WIDTH * index,
        onUpdate: () => {
          if (draggable) {
            draggable.update();
          }
          animateSignetAndImagesOnSlidesProgress();
        },
        onComplete: () => {
          setActiveSlideIndex(index);
        },
      });
    }
  };

  const animateSignetAndImagesOnSlidesProgress = () => {
    if (refSignet.current !== null && draggable) {
      gsap.set(refSignet.current, {
        x: () => {
          if(draggable) {
            return -draggable.x ;
          }

          return 0;
        }
      });

      Testimonials.map((_, index) => {
        if(draggable) {
          const value = mapAnimationRange(draggable.x, index);
          const target = refAllSlides.current[index].current?.children[0].children[2]?.children[1];

          if(target) {
            gsap.to(target!, {
              objectPosition: () => `${value}% 0%`,
            });
          }
        }

      });
    }
  };

  useEffect(() => {
    if (refSlider.current !== null && typeof window !== `undefined`) {
      gsap.registerPlugin(Draggable, InertiaPlugin);

      gsap.set(refSlider.current, {
        width: () => isMinTablet ? `${window.innerWidth-30}px` : `${MAX_TILE_WIDTH}px`,
      });

      const tileWidth = isMinTablet ? window.innerWidth : MAX_TILE_WIDTH;
      
      const MIN_X = -tileWidth * (Testimonials.length - 1);
      const MAX_X = 0;

      draggable = new Draggable(refSlider.current, {
        type: 'x',
        inertia: true,
        throwResistance: 50,
        dragResistance: 0,
        bounds: {
          minX: MIN_X,
          maxX: MAX_X,
          minY: 0,
          maxY: 0,
        },
        snap: {
          x: value => Math.round(value / window.innerWidth) * window.innerWidth,
        },
        onDrag: animateSignetAndImagesOnSlidesProgress,
        onThrowUpdate: animateSignetAndImagesOnSlidesProgress,
        onThrowComplete: () => {
          if(draggable) {
            const index = Math.round(draggable.x / tileWidth);
            index === 0 ? setActiveSlideIndex(index) : setActiveSlideIndex(-index);
          }
        },
        zIndexBoost: false,
      });
    }
  }, [Testimonials, refSlider.current, activeSlideIndex, windowSize]);

  useEffect(() => {
    goToSlide(activeSlideIndex);
  }, [windowSize]);

  return (
    <div className={s.testimonial} ref={refTestimonial}>
      <div className={s.testimonial__pagination}>
        {
          Testimonials.map((_, index) => {
            return (
              <div className={s.testimonial__bulletWrapper} key={index} onClick={() => goToSlide(index)}>
                <div className={`${s.testimonial__bullet} ${index === activeSlideIndex ? s.active : ''}`} />
              </div>
            );
          })
        }
      </div>
      <div className={s.testimonial__slides} >
        <div className={s.testimonial__box}>
          <div className={s.testimonial__wrapper} ref={refSlider}>
            {
              Testimonials.map((person,index) => {
                return getCard(person, refAllSlides.current[index], index)
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};
