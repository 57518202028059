import React, { ReactNode } from 'react';

import s from './Usp-header.module.scss';

interface UspHeaderProps {
  title: ReactNode | string;
  description: ReactNode | string;
}

export const UspHeader = ({title, description}: UspHeaderProps) => (
  <div className={s.uspHeader}>
    <h3 className={`${s.uspHeader__title} ${s.uspHeader__content}`}>{title}</h3>
    <p className={`${s.uspHeader__text} ${s.uspHeader__content}`}>{description}</p>
  </div>
);
