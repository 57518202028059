export const getAlternativeText = (triedText: string, emptyText: string) => {
  
  if(triedText === temporaryEmptyAlt) {
    return emptyText; 
  }
  else {
    return triedText;
  }
}

const temporaryEmptyAlt = '-';
