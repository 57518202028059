export enum ScrollDirection {
  up = 'up',
  down = 'down'
}

export enum SlideType {
  photo = 'photo',
  video = 'video'
}

export enum DirectionType {
  product = 'product',
  address = 'address'
}
