import React, { FC, RefObject } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { getAlternativeText } from '@helpers/altText.helper';
import s from './Atests.module.scss';


interface IAtests {
  refWrapper: RefObject<HTMLDivElement>;
}

export const Atests: FC<IAtests> = ({ refWrapper }) => {
  const intl = useIntl();
  
  return (
    <div className={s.atests} id={'atests'} ref={refWrapper}>
      <div className={`${s.atests__adacmscs} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/adac-mscs.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__3' }), intl.formatMessage({ id: 'alt__adac20' }))}
        />
      </div>
      <div className={`${s.atests__adac20} ${s.atests__box}`}>
        
        <StaticImage
          src={'../../../assets/images/adac20.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__11' }), intl.formatMessage({ id: 'alt__adac20' }))}
        />
      </div>
      <div className={`${s.atests__adac21} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/adac21.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__10' }), intl.formatMessage({ id: 'alt__adac21' }))}
        />
      </div>
      <div className={`${s.atests__avio} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/agr.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__9' }), intl.formatMessage({ id: 'alt__agr' }))}
        />
      </div>
      <div className={`${s.atests__idiada} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/idiada.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__8' }), intl.formatMessage({ id: 'alt__idiada' }))}
        />
      </div>
      <div className={`${s.atests__oamtcPixel} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/oamtc-pixel.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__7' }), intl.formatMessage({ id: 'alt__oamtcPixel' }))}
        />
      </div>
      <div className={`${s.atests__oamtcPixelIq} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/oamtc-pixel-iq.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__6' }), intl.formatMessage({ id: 'alt__oamtcPixelIq' }))}
        />
      </div>
      <div className={`${s.atests__tass} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/tass.jpg'}
          className={s.atests__atest}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__5' }), intl.formatMessage({ id: 'alt__tass' }))}
        />
      </div>
      <div className={`${s.atests__iSize} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/i-size.jpg'}
          className={s.atests__atestV}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__4' }), intl.formatMessage({ id: 'alt__iSize' }))}
        />
      </div>
      <div className={`${s.atests__plusTest105} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/plus-test-105.jpg'}
          className={s.atests__atestV}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__3' }), intl.formatMessage({ id: 'alt__plusTest105' }))}
        />
      </div>
      <div className={`${s.atests__plusTest125} ${s.atests__box}`}>
        <StaticImage
          src={'../../../assets/images/plus-test-125.jpg'}
          className={s.atests__atestV}
          alt={getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__2' }), intl.formatMessage({ id: 'alt__plusTest125' }))}
        />
      </div>
    </div>
  );
};
