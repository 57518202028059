import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { PageType } from '@constants/pageType.constants';
import { getArticleImage } from '@helpers/article.helper';
import { getAlternativeText } from '@helpers/altText.helper';

import { GuaranteeData } from '../../types/guarantee.types';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { Button } from '../button/Button';
import ArticleCard from '../article-card/ArticleCard.component';

import s from './WarrantyBannerHomePage.module.scss';
import sb from '../button/Button.module.scss';

import {Link} from "gatsby";
import {useLocalizedDynamicRoute} from "../../hooks/use-localized-dynamic-route";

interface IWarrantyBannerHomePage {
  banner: GuaranteeData,
}

export const WarrantyBannerHomePage = ({banner}:IWarrantyBannerHomePage) => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();
  const link = useLocalizedDynamicRoute();
  
  const warrantyArticle = getArticleImage(banner);

  const articleImageAltText = getAlternativeText(intl.formatMessage({ id: 'alternativeTexts__5' }), warrantyArticle.altText)
  
  return (
    <section className={s.warrantyBannerHomePage}>
      <div className={s.warrantyBannerHomePage__content}>
        <div className={s.warrantyBannerHomePage__article}>
          <StaticImage
            src={'../../assets/images/blend.png'}
            alt={articleImageAltText}
            className={s.warrantyBannerHomePage__background}
            objectFit={'cover'}
          />
          <div className={s.warrantyBannerHomePage__card}>
            <ArticleCard
              image={warrantyArticle.image}
              altText={articleImageAltText}
              header={warrantyArticle.header}
              articleLink={warrantyArticle.link}
              cta={intl.formatMessage({ id: 'warrantyBannerArticle_cta' })}
              currentLocale={intl.locale}
              isWarrantyBanner
              eventLabel={'homePage-warrantyBanner'}
            />
          </div>
        </div>
        <div className={s.warrantyBannerHomePage__information}>
          <h2 className={s.warrantyBannerHomePage__header} onClick={() =>
            sendDataToGTM({
              'event': 'warranty-banner-hp',
              'event_category': 'header click',
              'event_language': intl.locale,
            })
          }>{intl.formatMessage({ id: 'warrantySection__header' })}</h2>
          <p className={s.warrantyBannerHomePage__description} onClick={() =>
            sendDataToGTM({
              'event': 'warranty-banner-hp',
              'event_category': 'text click',
              'event_language': intl.locale,
            })
          }>{intl.formatMessage({ id: 'warrantySection__description' })}</p>
          <div className={s.warrantyBannerHomePage__button} onClick={() =>
            sendDataToGTM({
              'event': 'warranty-banner-hp',
              'event_category': 'button click',
              'event_language': intl.locale,
            })
          }>
            <Link className={`${sb.button} ${sb.yellow}`} to={link(PageType.WARRANTY)}>{intl.formatMessage({ id: 'guaranteeBanner__buttonText' })}</Link>
          </div>
        </div>
      </div>
    </section>
  )
}
