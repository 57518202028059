import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useWindowSize } from '../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import MobilePresentationComponent
  from '@components/popular-products/components/mobile-presentation/MobilePresentation.component';
import DesktopPresentationComponent
  from '@components/popular-products/components/desktop-presentation/DesktopPresentation.component';
import { Header } from '@components/header/Header.component';

import s from './PopularProducts.module.scss';


interface IPopularProducts {
  popularProducts: any;
}

const PopularProductsComponent = ({ popularProducts }: IPopularProducts) => {
  const intl = useIntl();
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.TABLET);


  return (
    <div className={s.popularProducts}>
      <Header>{intl.formatMessage({ id: 'product__popularProducts' })}</Header>
      <div className={s.popularProducts__products}>
        {
          isMobile ? <MobilePresentationComponent popularProducts={popularProducts}/> :
            <DesktopPresentationComponent popularProducts={popularProducts}/>
        }
      </div>
    </div>
  );
};

export default PopularProductsComponent;
