import {IDictrionary} from '../../types';

export const articleDictionaryHelper: IDictrionary = {
  'pl': 'co-zrobic-z-fotelikiem-po-wypadku',
  'de': 'was-sollte-man-mit-dem-autositz-nach-einem-unfall-machen',
  'en': 'what-do-you-do-with-a-child-car-seat-after-an-accident',
  'fr': 'que-faire-du-siege-auto-apres-un-accident',
  'es': 'what-do-you-do-with-a-child-car-seat-after-an-accident',
  'cz': 'co-delat-s-autosedackou-po-nehode',
  'hr': 'what-do-you-do-with-a-child-car-seat-after-an-accident',
}
