import React, {FC} from 'react';
import {graphql} from 'gatsby';
import { GatsbySeo, LogoJsonLd } from 'gatsby-plugin-next-seo/lib';
import {useIntl} from 'gatsby-plugin-intl';
import {AllWpGuarantee, ExplainerVideo, PhotoFileFluid, SliderEdge} from 'types';

import {getLink} from '@helpers/linking.helper';
import {knowledgeBaseLinkVisible} from '@helpers/knowledgeBase.helper';

import {PageType} from '@constants/pageType.constants';

import VideoAerofix from '@assets/videos/AEROFIX.mp4';
import VideoSky from '@assets/videos/SKY.mp4';
import VideoPixelPro from '@assets/videos/PIXELPRO.mp4';
import VideoMaxSpaceComfortSystem from '@assets/videos/MAXSPACECS.mp4';
import VideoCosmo from '@assets/videos/COSMO.mp4';
import Thumbnail from '@assets/thumbnails/avionaut_thumbnail.jpg';

import {HeroSlider} from '@components/hero-slider/HeroSlider.component';
import {Explainer} from '@components/explainer/Explainer.component';
import {Follow} from '@components/follow/Follow.component';
import {Usp} from '@components/usp/Usp.component';
import {Proofs} from '@components/proofs/Proofs.component';
import {MeetVisionAvionaut} from '@components/meet-vision-avionaut/MeetVisionAvionaut.component';
import {MedicalApprovement} from '@components/medical-approvement/MedicalApprovement.component';
import {WarrantyBannerHomePage} from '@components/warranty-banner-home-page/WarrantyBannerHomePage.component';
import {FeaturedCategories} from '@components/featured-categories/FeaturedCategories.component';
import DontMissArticles from '@components/dont-miss-articles/DontMissArticles.component';
import InfoBox from '@components/info-box/InfoBox.component';
import s from './Homepage.module.scss';
import PostAccidentReplacementHomePage
  from '@components/post-accident-replacement-home-page/PostAccidentReplacementHomePage.component';
import {TemplateHelper} from '@helpers/templates.helper';
import PopularProductsComponent from '@components/popular-products/PopularProducts.component';
import ProducerAvionaut from '@components/producer-avionaut/ProducerAvionaut.component';

interface IHomepage {
  data: {
    allWpSlider: {
      edges: SliderEdge[];
    };
    allWpGuarantee: AllWpGuarantee;
    videoPoster: PhotoFileFluid;
    latestVideos: any;
    latestArticles: any;
    latestGuides: any;
  };
}

const HomepageTemplate: FC<IHomepage> = (props) => {
  const {
    allWpSlider,
    allWpGuarantee,
    wpFeaturedProduct,
    videoPoster,
    latestVideos,
    latestArticles,
    latestGuides
  } = props.data;

  const intl = useIntl();

  const guaranteeBanner = allWpGuarantee.edges[0].node.guaranteeData;
  const popularProducts = wpFeaturedProduct;
  
  const lastPosts = [latestVideos.edges, latestArticles.edges, latestGuides.edges]
    .map(TemplateHelper.getEdges)
    .flat(Infinity)
    .sort(TemplateHelper.sortByDatePosts)
    .filter(edge => edge.node.contentTypeName !== 'video')
    .slice(0, 4);
  
  const videos: ExplainerVideo[] = [
    {
      name: 'PIXELPRO',
      title: intl.formatMessage({id: 'explainer__firstTitle'}),
      description: intl.formatMessage({id: 'explainer__firstDescription'}),
      src: VideoPixelPro,
    },
    {
      name: 'AEROFIX',
      title: intl.formatMessage({id: 'explainer__secondTitle'}),
      description: intl.formatMessage({id: 'explainer__secondDescription'}),
      src: VideoAerofix,
    },
    {
      name: 'SKY',
      title: intl.formatMessage({id: 'explainer__thirdTitle'}),
      description: intl.formatMessage({id: 'explainer__thirdDescription'}),
      src: VideoSky,
    },
    {
      name: 'MAXSPACECOMFORTSYSTEMPLUS',
      title: intl.formatMessage({id: 'explainer__fourthTitle'}),
      description: intl.formatMessage({id: 'explainer__fourthDescription'}),
      src: VideoMaxSpaceComfortSystem,
    },
    {
      name: 'COSMO',
      title: intl.formatMessage({id: 'explainer__fifthTitle'}),
      description: intl.formatMessage({id: 'explainer__fifthDescription'}),
      src: VideoCosmo,
    },
  ];
  
  return (
    <>
      <LogoJsonLd
        logo={'https://avionaut.com/avionaut.svg'}
        url={`https://avionaut.com${getLink(intl.locale, PageType.START)}`}
      />
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale,
        }}
        language={intl.locale}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.START)}`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.START)}`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.START)}`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.START)}`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.START)}`,
          },
          {
            hrefLang: 'hr',
            href: `https://avionaut.com${getLink('hr', PageType.START)}`,
          },
          {
            hrefLang: 'es',
            href: `https://avionaut.com${getLink('es', PageType.START)}`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.START)}`,
          },
        ]}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.START)}`}
        title={`${intl.locale === 'pl' || intl.locale === 'de' ? '' : 'Avionaut - '}${intl.formatMessage({id: 'metaTitle__homepage'})}`}
        description={intl.formatMessage({id: 'metaDescription__homepage'})}
        metaTags={[
          {
            property: 'thumbnail',
            content: Thumbnail,
          },
          {
            property: 'og:title',
            content: intl.formatMessage({id: 'metaTitle__homepage'}),
          },
          {
            property: 'og:description',
            content: intl.formatMessage({id: 'metaDescription__homepage'}),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({id: 'metaTitle__homepage'}),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({id: 'metaDescription__homepage'}),
          },
        ]}
      />
      <div className={s.homepage}>
        <HeroSlider edges={allWpSlider.edges}/>
        <PopularProductsComponent popularProducts={popularProducts.featuredProducts.products} />
        <ProducerAvionaut />
        <WarrantyBannerHomePage
          banner={guaranteeBanner}
        />
        <Explainer
          videos={videos}
          videoPoster={videoPoster}
          header={intl.formatMessage({id: 'home__explainerHeader'})}
        />
        <PostAccidentReplacementHomePage />
        <MedicalApprovement/>
        <MeetVisionAvionaut/>
        <Proofs/>
        <Usp/>
        {
          intl.locale === 'pl' ?
            <InfoBox /> :
            <Follow buttonText={intl.formatMessage({ id: 'home__followUsButton' })} />
        }
        {
          knowledgeBaseLinkVisible.indexOf(intl.locale) > -1 && <DontMissArticles posts={lastPosts} />
        }
      </div>
    </>
  );
};

export const query = graphql`
  query GetSlidesAndFeaturedProducts($locale: String) {
    allWpSlider(sort: {fields: slideData___ordernumber}, filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          locale {
            locale
          }
          title
          slideData {
            connectedproduct {
              ... on WpProduct {
                slug
              }
            }
            slidetype
            directiontype
            directionaddress
            buttontext
            formatabletext
            slidevideocode
            slidevideocover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 90)
                }
              }
            }
            obrazek {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 90)
                }
              }
            }
            imgmobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
            }
          }
        }
      }
    }
    wpFeaturedProduct(locale: {locale: {eq: $locale}}, slug: {eq: "popularne-produkty"}) {
      locale {
        locale
      }
      featuredProducts {
        products {
          ... on WpProduct {
            productMetaData {
              productname
              thumbnail {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
              featuredproductsmeta {
                categoryage
                categoryweight
              }
            }
            productData {
              productavailablecolors {
                ... on WpColor {
                  id
                  colorProperties {
                    colorhex
                    colorname
                    colorparameter
                  }
                }
              }
              avionautShopUrl
              thumbnailphotos {
                productcolorversion {
                  ... on WpColor {
                    colorProperties {
                      colorhex
                      colorname
                      colorparameter
                    }
                  }
                }
                photo {
                  id
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, quality: 100)
                    }
                  }
                }
              }
              tags
            }
            productMetaData {
              newcolorversionavailable
              iscompatiblewithiqbase
              iscompatiblewithiqbase20c
              iscloudcare
              isproductvisible
              isproductrwf
            }
            slug
          }
        }
      }
      slug
    }
    allWpGuarantee(filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          guaranteeData {
            textBanner
            banner {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
            }
            warrantyArticle {
              ... on WpArticle {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100)
                      }
                    }
                  }
                }
              }
              ... on WpVideo {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100)
                      }
                    }
                  }
                }
              }
              ... on WpGuide {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100)
                      }
                    }
                  }
                }
              }
            }
            warrantyArticleLink
            warrantyArticleHeader
            warrantyArticleImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
            }
          }
        }
      }
    }
    videoPoster: file(name: {eq: "video-slider-poster"}) {
      name
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, quality: 100)
      }
    }
    latestVideos: allWpVideo(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
      }

    ){
      edges {
        node {
          ...VideoInfo
          ...VideoImage
        }
      }
    }
    latestArticles: allWpArticle(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
      }
    ) {
      edges {
        node {
          ...ArticleInfo
          ...ArticleImage
        }
      }
    }
    latestGuides: allWpGuide(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
      }
    ) {
      edges {
        node {
          ...GuideInfo
          ...GuideImage
        }
      }
    }
  }
`;

export default HomepageTemplate;
