import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { Container } from '@components/container/Container.component';
import Box from './components/Box.component';

import { replaceNbsp } from '@helpers/translation.helper';

import s from './InfoBox.module.scss';
import sanitizeHtml from 'sanitize-html';


const InfoBox = () => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();

  const [collapseState, setCollapseState] = useState<boolean[]>([false, false, false, false]);
  const firstColumn = useRef<HTMLDivElement>(null);
  const secondColumn = useRef<HTMLDivElement>(null);

  const handleSetCollapseState = (index: number, value: boolean) => {
    const tempCollapseState = [...collapseState];

    tempCollapseState[index] = value;
    setCollapseState(tempCollapseState);
  };

  const boxes: {
    header: string;
    information: ReactNode;
  }[] = [
    {
      header: replaceNbsp(intl.formatMessage({ id: 'infoBox__1__heder' })),
      information:
        <>
          <p className={s.infoBox__information} dangerouslySetInnerHTML={{
            __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__1__information_1' })), {
              allowedTags: ['strong', 'a', 'br'],
              allowedAttributes: {
                'a': ['href'],
              },
            }),
          }}/>
          <br/>
          <Collapse isOpened={collapseState[0]}>
            <p className={s.infoBox__information} dangerouslySetInnerHTML={{
              __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__1__information_2' })), {
                allowedTags: ['strong', 'a', 'br'],
                allowedAttributes: {
                  'a': ['href'],
                },
              }),
            }}/>
            <br/>
            <p className={s.infoBox__information} dangerouslySetInnerHTML={{
              __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__1__information_3' })), {
                allowedTags: ['strong', 'a', 'br'],
                allowedAttributes: {
                  'a': ['href'],
                },
              }),
            }}/>
          </Collapse>
          <br/>
          <p className={`${s.infoBox__information} ${s.infoBox__bold}`}
                         onClick={() => {
                           handleSetCollapseState(0, !collapseState[0]);
                           if(!collapseState[0]){
                             sendDataToGTM({
                               'event': 'info-box',
                               'event_category': 'expand',
                               'event_label': 0,
                               'event_language': intl.locale,
                             })
                           }
                         }}>
          {collapseState[0] ? intl.formatMessage({ id: 'infoBox__collapse' }) : intl.formatMessage({ id: 'infoBox__expand' })}
        </p>
        </>,
    },
    {
      header: replaceNbsp(intl.formatMessage({ id: 'infoBox__2__heder' })),
      information:
        <>
          <p className={s.infoBox__information} dangerouslySetInnerHTML={{
            __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__2__information_1' })), {
              allowedTags: ['strong', 'a', 'br'],
              allowedAttributes: {
                'a': ['href'],
              },
            }),
          }}/>
          <br/>
          <Collapse isOpened={collapseState[1]}>
            <ul className={s.infoBox__information} dangerouslySetInnerHTML={{
              __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__2__information_2' })), {
                allowedTags: ['strong', 'a', 'br', 'li'],
                allowedAttributes: {
                  'a': ['href'],
                },
              }),
            }}/>
          </Collapse>
          <br/>
          <p className={`${s.infoBox__information} ${s.infoBox__bold}`}
             onClick={() => {
               handleSetCollapseState(1, !collapseState[1])
               if(!collapseState[1]){
                 sendDataToGTM({
                   'event': 'info-box',
                   'event_category': 'expand',
                   'event_label': 1,
                   'event_language': intl.locale,
                 })
               }
             }}>
            {collapseState[1] ? intl.formatMessage({ id: 'infoBox__collapse' }) : intl.formatMessage({ id: 'infoBox__expand' })}
          </p>
        </>,
    },
    {
      header: replaceNbsp(intl.formatMessage({ id: 'infoBox__3__heder' })),
      information:
        <>
          <p className={s.infoBox__information} dangerouslySetInnerHTML={{
            __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__3__information_1' })), {
              allowedTags: ['strong', 'a', 'br'],
              allowedAttributes: {
                'a': ['href'],
              },
            }),
          }}/>
          <br/>
          <Collapse isOpened={collapseState[2]}>
            <p className={s.infoBox__information} dangerouslySetInnerHTML={{
              __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__3__information_2' })), {
                allowedTags: ['strong', 'a', 'br'],
                allowedAttributes: {
                  'a': ['href'],
                },
              }),
            }}/>
          </Collapse>
          <br/>
          <p className={`${s.infoBox__information} ${s.infoBox__bold}`}
             onClick={() => {
               handleSetCollapseState(2, !collapseState[2])
               if(!collapseState[2]){
                 sendDataToGTM({
                   'event': 'info-box',
                   'event_category': 'expand',
                   'event_label': 2,
                   'event_language': intl.locale,
                 })
               }
             }}>
            {collapseState[2] ? intl.formatMessage({ id: 'infoBox__collapse' }) : intl.formatMessage({ id: 'infoBox__expand' })}
          </p>
        </>,
    },
    {
      header: replaceNbsp(intl.formatMessage({ id: 'infoBox__4__heder' })),
      information:
        <>
          <p className={s.infoBox__information} dangerouslySetInnerHTML={{
            __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__4__information_1' })), {
              allowedTags: ['strong', 'a', 'br'],
              allowedAttributes: {
                'a': ['href'],
              },
            }),
          }}/>
          <br/>
          <Collapse isOpened={collapseState[3]}>
            <p className={s.infoBox__information} dangerouslySetInnerHTML={{
              __html: sanitizeHtml(replaceNbsp(intl.formatMessage({ id: 'infoBox__4__information_2' })), {
                allowedTags: ['strong', 'a', 'br'],
                allowedAttributes: {
                  'a': ['href'],
                },
              }),
            }}/>
          </Collapse>
          <br/>
          <p className={`${s.infoBox__information} ${s.infoBox__bold}`}
             onClick={() => {
               handleSetCollapseState(3, !collapseState[3]);
               if(!collapseState[3]){
                 sendDataToGTM({
                   'event': 'info-box',
                   'event_category': 'expand',
                   'event_label': 3,
                   'event_language': intl.locale,
                 })
               }
             }}>
            {collapseState[3] ? intl.formatMessage({ id: 'infoBox__collapse' }) : intl.formatMessage({ id: 'infoBox__expand' })}
          </p>
        </>,
    },
  ];
  
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
    }
  }, []);

  useEffect(() => {
      gsap.set([firstColumn.current, secondColumn.current], {
        autoAlpha: 0,
      });

      const animation = gsap.fromTo([firstColumn.current, secondColumn.current], { y: '+=200' }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.8,
        ease: 'sine.out',
        scrollTrigger: {
          trigger: `#usp`,
          start: '70% center',
          markers: false,
          toggleActions: 'play none none reverse',
        },
      });

      return () => {
        animation.kill();
      };
  }, []);

  return (
    <section>
      <Container>
          <div className={s.infoBox__content}>
            <div className={s.infoBox__first_column} ref={firstColumn}>
              <Box header={boxes[0].header} information={boxes[0].information}/>
              <Box header={boxes[2].header} information={boxes[2].information}/>
            </div>
            <div className={s.infoBox__second_column} ref={secondColumn}>
              <Box header={boxes[1].header} information={boxes[1].information}/>
              <Box header={boxes[3].header} information={boxes[3].information}/>
            </div>
          </div>
      </Container>
    </section>
  );
};

export default InfoBox;
